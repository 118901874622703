import React from "react"
// import { Link } from "gatsby"

import {  Link } from "gatsby-plugin-intl"

import LayoutBackgroundBurrito from "../components/layout-background-burrito"
import "../styles/vars.scss"
import indexStyles from "../styles/index.module.scss"

const IndexPage = () => {
  // const intl = useIntl()

  return (
    <LayoutBackgroundBurrito>
      <div className={indexStyles.container}>
        <ul className={indexStyles.quickList}>
          <li className={indexStyles.quickListItem}>
            <Link className={indexStyles.quickListItemLink} activeClassName={indexStyles.activeQuickListItem} to="/food">eat</Link>
          </li>
          <li className={indexStyles.quickListItem}>
            <Link className={indexStyles.quickListItemLink} activeClassName={indexStyles.activeQuickListItem} to="/shop">Shop</Link>
          </li>
          <li className={indexStyles.quickListItem}>
            <Link className={indexStyles.quickListItemLink} activeClassName={indexStyles.activeQuickListItem} to="/visit">Visit</Link>
          </li>
        </ul>

      </div>      
    </LayoutBackgroundBurrito>
  )
}

export default IndexPage
